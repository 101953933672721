// reducers/counter.ts

import HeaderConstant from "../constant/HeaderConstant";
import {HeaderActions, InitialHeaderState} from "../types";

const initialHeaderState: InitialHeaderState = {
    tabData: [
        {
            label: 'SELECT DIAMOND',
            isActive: true,
            isDisabled: false,
            isIcon: false
        },
        {
            label: 'SELECT SETTING',
            isActive: false,
            isDisabled: false,
            isIcon:false,
            openTab:'diamond'
        }
    ]
}

const HeaderReducer = (state: InitialHeaderState = initialHeaderState, action: HeaderActions) => {
    switch (action.type) {
        case HeaderConstant.SET_HEADER_TAB_TO_DEFAULT:
            return {...initialHeaderState};
        case HeaderConstant.SET_HEADER_TAB_TO_SETTING_OWN:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT SETTING',
                        isActive: true,
                        isDisabled: false,
                        isIcon:false,
                        openTab:action.payload
                    },
                    {
                        label: 'SELECT DIAMOND',
                        isActive: false,
                        isDisabled: false,
                        isIcon: false
                    }
                ]
            };
        case HeaderConstant.SET_HEADER_TAB_DIAMOND_ICON:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT DIAMOND',
                        isActive: false,
                        isDisabled: true,
                        isIcon: true
                    },
                    {
                        label: 'SELECT SETTING',
                        isActive: true,
                        isDisabled: false,
                        isIcon: false,
                        openTab:action.payload

                    }
                ]
            };
        case HeaderConstant.SET_HEADER_TAB_SETTING_ICON:
            return {
                ...state,
                tabData: [
                    {
                        label: 'SELECT SETTING',
                        isActive: false,
                        isDisabled: true,
                        isIcon: true
                    },
                    {
                        label: 'SELECT DIAMOND',
                        isActive: true,
                        isDisabled: false,
                        isIcon: false
                    }
                ]
            };
        case HeaderConstant.SET_INITIAL_HEADER:
            return initialHeaderState;
        default:
            return state;
    }
}

export default HeaderReducer;