import {MRT_ColumnDef} from "../Tanstack-Table/lib";
import {useMediaQuery} from "react-responsive";
import {useEffect, useMemo, useState} from "react";
import {FilterState, InitialTableState, TableData} from "../../types";

const mobileViewColumns: MRT_ColumnDef<TableData>[] = [
    {
        accessorKey: 'img',
        header: 'IMAGE',
        size:100,
        isNotShowSorting: true
    },
    {
        accessorKey: 'field',
        header: 'SHAPE',
        size:200,
        isNotShowSorting: true
    },
    {
        accessorKey: 'price',
        header: 'TYPE',
        size:120,
        isNotShowSorting: true

    },
]

const tabViewColumns: MRT_ColumnDef<TableData>[] = [
    {
        accessorKey: 'img',
        header: 'IMAGE',
        size:55,
        isNotShowSorting: true
    },
    {
        accessorKey: 'source',
        header: 'TYPE',
        size:80,
        isNotShowSorting: false
    },
    /*{
        accessorKey: 'shape',
        header: 'SHAPE',
        size:80,
        isNotShowSorting: false
    },*/
    {
        accessorKey: 'carat',
        header: 'CARAT',
        size:75,
        isNotShowSorting: false
    },
    {
        accessorKey: 'diamond_price',
        header: 'PRICE',
        size:110,
        isNotShowSorting: false
    },
    {
        accessorKey: 'color',
        header: 'COLOR',
        size:80,
        isNotShowSorting: false
    },
    {
        accessorKey: 'clarity',
        header: 'CLARITY',
        size:90,
        isNotShowSorting: false
    },
    {
        accessorKey: 'lab',
        header: 'CERTIFICATION',
        size:120,
        isNotShowSorting: false
    },
    {
        accessorKey: 'compare',
        header: 'COMPARE',
        size:60,
        isNotShowSorting: true
    }
]

const desktopViewColumns: MRT_ColumnDef<TableData>[] = [
    {
        accessorKey: 'img',
        header: 'IMAGE',
        size:60,
        isNotShowSorting: true
    },
    {
        accessorKey: 'source',
        header: 'TYPE',
        size:100,
        isNotShowSorting: false
    },
    {
        accessorKey: 'shape',
        header: 'SHAPE',
        size:85,
        isNotShowSorting: false
    },
    {
        accessorKey: 'carat',
        header: 'CARAT',
        size:85,
        isNotShowSorting: false
    },
    {
        accessorKey: 'diamond_price',
        header: 'PRICE',
        size:120,
        isNotShowSorting: false
    },
    {
        accessorKey: 'color',
        header: 'COLOR',
        size:80,
        isNotShowSorting: false
    },
    {
        accessorKey: 'cut',
        header: 'CUT',
        size:85,
        isNotShowSorting: false
    },
    {
        accessorKey: 'clarity',
        header: 'CLARITY',
        size:90,
        isNotShowSorting: false
    },
    {
        accessorKey: 'lab',
        header: 'CERTIFICATION',
        size:130,
        isNotShowSorting: false
    },
    {
        accessorKey: 'scs',
        header: 'SCS',
        size:70,
        isNotShowSorting: false
    },
    {
        accessorKey: 'fluorecence',
        header: 'FLUORECENCE',
        size:125,
        isNotShowSorting: false
    },
    {
        accessorKey: 'polish',
        header: 'POLISH',
        size:100,
        isNotShowSorting: false
    },
    {
        accessorKey: 'symmetry',
        header: 'SYMMETRY',
        size:130,
        isNotShowSorting: false
    },
    {
        accessorKey: 'compare',
        header: 'COMPARE',
        size:60,
        isNotShowSorting: true
    }
]

const UseTable = ({data, table,filter}: { data: TableData[], table: InitialTableState,filter:FilterState }) => {
    // If Limited the data needed
    const isMobileView = useMediaQuery({query: "(max-width: 767px)"});
    const isTabView = useMediaQuery({query: "(max-width: 1350px)"});
    const [compareDataIds,setCompareDataIds] = useState<string[]>([]);

    // If All the data needed
    // const isMobileView = false;
    // const isTabView = false;
    const [columns, setColumns] = useState<MRT_ColumnDef<TableData>[]>(isMobileView ?mobileViewColumns :isTabView?tabViewColumns:desktopViewColumns);

    useEffect(()=>{
        const scsData = {
            accessorKey: 'scs',
            header: 'SCS',
            size: 70,
            isNotShowSorting: false
        };

        if (!isMobileView && !isTabView) {
            if (Object.keys(filter.options).length > 0 && filter.options.source) {
                // Check if source is 'GNU'
                if (filter.options.source === 'GNU') {
                    // Remove 'scs' column if source is 'GNU'
                    const filteredColumns = columns.filter(column => column.accessorKey !== 'scs');
                    setColumns(filteredColumns);
                } else {
                    // Find 'scs' column
                    const scsColumnExists = columns.some(column => column.accessorKey === 'scs');

                    if (!scsColumnExists && columns.length > 0) {
                        // Find index of 'lab' column
                        const labColumnIndex = columns.findIndex(column => column.accessorKey === 'lab');

                        if (labColumnIndex !== -1) {
                            // Insert 'scs' data after 'lab' column
                            const newData = [...columns];
                            newData.splice(labColumnIndex + 1, 0, scsData);
                            setColumns(newData);
                        }
                    }
                }
            } else {
                // Set desktop view columns if filter options are empty
                setColumns(isMobileView ?mobileViewColumns :isTabView?tabViewColumns:desktopViewColumns);
            }
        }
    },[filter.options,isMobileView,isTabView,window.innerWidth])

    useEffect(() => {
        isMobileView ? setColumns(mobileViewColumns)
            : isTabView ? setColumns(tabViewColumns) : setColumns(desktopViewColumns);
    }, [isMobileView, isTabView])

    const tableData = useMemo(() => {
        if (!isMobileView) {
            const activeTab:number = ((table.recentData.length === 0) && (table.activeTab === 2)) ? (table.activeTab +1) : table.activeTab;
            return data.map((item: TableData) => ({
                ...item,
                isExpanded: table.expandRow[activeTab]?.toString() === item?.id?.toString()
            }));
        } else {
            return data.map((item: any) => ({
                ...item,
                img: {
                    img: item.img,
                    shape:item.shape
                },
                field: {
                    // shape: item.shape,
                    carat: item.carat,
                    color: item.color,
                    clarity: item.clarity,
                    cut: item.cut
                },
                price: item.diamond_price
            }));
        }
    }, [data, table.expandRow, table.activeTab, isMobileView, isTabView,table.recentData]);

    useEffect(()=>{
       let compareDataList:string[] = [];
           table.compareData.map((item:TableData)=>{
               compareDataList = [...compareDataList,item.id as string];
           });
       setCompareDataIds(compareDataList);
    },[table])

    return {
        isMobileView,
        isTabView,
        columns,
        tableData,
        compareDataIds
    }
}

export default UseTable;
