import { useCallback, useEffect, useState } from "react";
import {iFrameHost, PropsFromCartRedux} from "./BuildOwn";
import { PropsFromScreenManager } from "../../types";

const useBuildOwn = (props:PropsFromScreenManager & PropsFromCartRedux) => {
  const {instanceData: { screenManager },ring,footer,setCartImageSuccess,setCartThumbImageSuccess} = props
  const [isIcon, setIsIcon] = useState(true);
  const [viewSrc, setViewSrc] = useState("");
  const [iframeLoadActionCount, setIframeLoadActionCount] = useState<number>(0);
  const currentIcon = !isIcon ? "hk-AR" : "hk-image";
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);

  useEffect(() => {
    setViewSrc(iFrameHost);
  }, []);
  useEffect(()=>{
    if (Object.keys(ring.options).length > 0) {
      setImage([]);
      setThumbImage([]);
      const { Ring_Style, Ring_Crown, Center_Diamond_Size,Ring_Side, Diamond_Shape, Color, Single_First_Band, Double_First_Band, Double_Second_Band,Wedding_Band } = ring.options;
      // const style = `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
      if(footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side){
        const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-200-${Ring_Side}` : `${footer.styleID}-PKRCLP-RND-200-PRG`;
        ["V1", "V2", "V3", "V4"].forEach((view: string) => {
          const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}-${Single_First_Band || Double_First_Band || Color }-${Double_Second_Band || Color }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/2000/fafafa/random.jpg`;
          const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}-${Single_First_Band || Double_First_Band || Color }-${Double_Second_Band || Color }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/150/fafafa/random.jpg`;
          setImage((prev: string[]) => {
            return [...prev, imagePath];
          });
          setThumbImage((prev: string[]) => {
            return [...prev, thumbPath];
          });
        });
      }
    }
  },[ring.options])
  useEffect(()=>{
    setCartImageSuccess(image);
  },[image])
  useEffect(()=>{
    setCartThumbImageSuccess(thumbImage);
  },[thumbImage])
  const handleChangeIcon = useCallback(() => {
    setIsIcon((icon) => !icon);
  }, [setIsIcon]);

  useEffect(() => {
    (screenManager as any).props.setStateManagerData(
      "currentScreen",
      "Select Settings screen"
    );
    (screenManager as any).props.setStateManagerData(
      "currentView",
      "Select Settings Main View"
    );
  }, []);

  return {
    img:image,
    thumbImage,
    viewSrc,
    currentIcon,
    handleChangeIcon,
    isIcon,
    iframeLoadActionCount,
    setIframeLoadActionCount,
  };
};
export default useBuildOwn;
