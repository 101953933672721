import React, {FC} from "react";
import classes from "./StickyFooter.module.css";
import Cart from "../Cart";
import { RootState } from "../../reducers/RootReducer";
import { setFooterToggle,setFooterHeight } from "../../action/FooterAction";
import { connect, ConnectedProps } from "react-redux";
import useStickyFooter from "./useStickyFooter";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {PropsFromScreenManager} from '../../types';
import {setSelectedRingDetails} from '../../action/RingAction';
import {DiamondLabels} from "../../Utils/commonData";

const mapStateToProps = (state: RootState) => ({
  footer: state.footer,
  diamond: state.diamond,
  ring: state.ring,
  filter: state.filter,
});
const mapDispatchToProps = {
  setFooterToggle,
  setFooterHeight,
  setSelectedRingDetails
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromFooterRedux = ConnectedProps<typeof connector>;
const StickyFooter: FC<PropsFromFooterRedux & PropsFromScreenManager> = ({
  diamond,
  ring,
  footer,
  setFooterToggle,
  setFooterHeight,
  filter,
  instanceData,
  setSelectedRingDetails
}) => {
  const { wrapperRef, handleClick } = useStickyFooter({
    setFooterToggle,
    setFooterHeight,
    diamond,
    footer,
    ring,
    filter,
    setSelectedRingDetails
  });
  const toggledClass = footer.toggle ? classes.active : "";
  const { carat, shape, source, diamond_price } = diamond.details;
  const { Ring_Crown, Ring_Side, Ring_Style, Metal, Color } = ring.selectedRingDetails;

  return (
    <>
      {footer.isShowFooter
        ? (
        <div className={classes.root}>
          <div
            className={`${classes.curtain} ${
              footer.toggle ? classes.isOpen : ""
            }`}
          >
            <Cart instanceData={instanceData}/>
          </div>
          {(!(Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0 && footer.toggle)) && <div className={classes.fluide}>
            <div ref={wrapperRef} className={classes.wrapper}>
              {/*<div className={classes.arrow}>
                <Button
                  type={"button"}
                  buttonType="btnIcon"
                  className={`${classes.button} ${toggledClass}`}
                  onClick={handleClick}
                >
                  <Icon name="hk_chevron_up" />
                </Button>
              </div>*/}
              <div className={classes.content}>
                <h3 className={classes.title}>Your Engagement Ring</h3>
                <ul className={classes.detailList}>
                  {Object.keys(diamond.details).length > 0 ? <li className={classes.detail}>
                    {carat} ct. {DiamondLabels[shape as string]} {source} Diamond {" "}
                    <span className={classes.price}>${ToLocalStringConvert(diamond_price?+diamond_price:0)}</span>
                  </li> : ''}
                  {Object.keys(ring.selectedRingDetails).length > 0 ? <li className={classes.detail}>
                    {Ring_Style} {footer.styleID?.includes('RB') ? `${Ring_Crown} ${Ring_Side}` : ''} { Metal === 'Platinum' ? Metal : `${Metal.split(' ')[0]} ${Color} ${Metal.split(' ')[1]}`} Ring {ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''} {" "}
                    {ring.isPriceLoading ? <span className={classes.price}>Loading...</span> :<span className={classes.price}>${ToLocalStringConvert(ring.ringPrice)}</span>}
                  </li> : ''}
                </ul>
              </div>
              {ring.isPriceLoading ? <div>Loading...</div> :
                <div className={classes.total}>
                  <span className="text-padding-top">
                    {
                      (Object.keys(ring.options).length > 0)
                        ? diamond_price
                        ? `$${ToLocalStringConvert(+diamond_price + +ring.ringPrice)}`
                        : `$${ring.ringPrice}`
                        : diamond_price
                        ? `$${ToLocalStringConvert(+diamond_price)}`
                        : ''
                    }
                  </span>
                </div>
              }
            </div>
          </div>}
        </div>
      ) : null}
    </>
  );
};

export default connector(StickyFooter);
