import React from "react";
import Icon from "../Icons";
import {
  HKDiamondDetail1,
  HKDiamondDetail2,
  HKDiamondDetail3,
  HKDiamondDetail4,
  HKDiamondDetail5
} from "../Images";
import DiamondDetail from "../Table/DiamondDetail";
import Button from "../Button/Button";
import classes from "./DiamondDetailsPage.module.css";
import DiamondOtherDetails from "../DiamondOtherDetails";
import MainImageSlider from "../MainImageSlider";
import useDiamondDetailsPage from "./useDiamondDetailsPage";
import DialogBox from "../DialogBox/DialogBox";
import {RootState} from "../../reducers/RootReducer";
import {setInitialDiamondOption,setDiamondDetailsSuccess,setDiamondDetailsLoading,setDiamondDetailsFail} from "../../action/DiamondAction";
import {setFooterToggle,setIsShowFooter} from "../../action/FooterAction";
import {setInitialOption,setBackDiamondDetails,setJourneyStartedFrom} from "../../action/RingAction";
import {connect, ConnectedProps} from "react-redux";
import Loader from "../Loader/Loader";
import {setDiamondTabIconTrue} from '../../action/HeaderAction';
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import { PropsFromScreenManager } from '../../types';

import {recentDataSuccess} from "../../action/TableAction";
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
const mapStateToProps = (state: RootState) => ({
  diamond: state.diamond,
  header:state.header,
  ring: state.ring,
  filter: state.filter
});

const mapDispatchToProps = {
  setDiamondTabIconTrue,
  setDiamondDetailsSuccess,
  setDiamondDetailsLoading,
  setDiamondDetailsFail,
  setInitialDiamondOption,
  setFooterToggle,
  setInitialOption,
  setBackDiamondDetails,
  recentDataSuccess,
  setIsShowFooter,
  setJourneyStartedFrom
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromDiamondDetailsRedux = ConnectedProps<typeof connector>;
const DiamondDetailsPage: React.FC<PropsFromDiamondDetailsRedux & PropsFromScreenManager> = ({
                                                                                               diamond,
                                                                                               ring,
                                                                                               setFooterToggle,
                                                                                               recentDataSuccess,
                                                                                               setInitialDiamondOption,
                                                                                               setDiamondDetailsSuccess,
                                                                                               setDiamondDetailsLoading,
                                                                                               setDiamondDetailsFail,
                                                                                               setDiamondTabIconTrue,
                                                                                               setInitialOption,
                                                                                               setBackDiamondDetails,
                                                                                               setIsShowFooter,
                                                                                               instanceData,
                                                                                               setJourneyStartedFrom
                                                                                             }) => {

  const img: string[] = [
    HKDiamondDetail1,
    HKDiamondDetail2,
    HKDiamondDetail3,
    HKDiamondDetail4,
    HKDiamondDetail5
  ];
  const {
    navigate,
    isOpenDialog,
    closeDialogBox,
    handleSelectThisDiamond,
    handleBackDiamondSearch,
    dialogDetails
  } = useDiamondDetailsPage(
     {
       setDiamondDetailsSuccess,
       setDiamondDetailsLoading,
       recentDataSuccess,
       setIsShowFooter,
       setInitialOption,
       setBackDiamondDetails,
       setDiamondDetailsFail,
       setInitialDiamondOption,
       ring,
       setFooterToggle,
       diamond,
       setDiamondTabIconTrue,
       instanceData,
       setJourneyStartedFrom
     });
  if(diamond.isLoading){
    return <div className={'loading-details'}><Loader position='absolute' /></div>
  }
  if(diamond.error){
    return <div>Error...</div>
  }
  const {image_link,carat,shape,color,clarity,cut,source,diamond_price} = diamond.details;

  if (image_link && (image_link !== null || image_link !== undefined)) {
    img.unshift(image_link as string);
  }

  return (
    <>
      <Header instanceData={instanceData}/>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={`${classes.leftSideContainer}`}>
            <div className={`${classes.sliderContainer} sliderRef`} >
              <MainImageSlider is360={false} img={img} isIcon={false}/>
            </div>
          </div>

            <div
              className={classes.rightSideContainer}
            >
              <div className={classes.content}>
                <DiamondDetail
                  carat={`${carat ? `${carat} ct` : ''}`}
                  color={color}
                  clarity={clarity}
                  cut={cut}
                  title={`${carat ? `${carat} ct` : ''} ${shape ?(shape as string) : ''} ${source ? `${source} Diamond`: ''} `}
                  price={ToLocalStringConvert(diamond_price ? +diamond_price :0)}
                />
                <div className={classes.buttonSection}>
                  <Button
                    type={"button"}
                    className={classes.button}
                    buttonType={'btnPrimary'}
                    onClick={handleSelectThisDiamond}
                  >
                    {/*<Icon name={"hk-viewDiamond"} />*/}
                    <span className="text-padding-top-25">Select This Diamond</span>
                  </Button>
                  <Button
                    type={'button'}
                    className={classes.backButton}
                    onClick={() => handleBackDiamondSearch()}
                  >
                    <div className={classes.navigate}>
                        {/*<Icon name={"hk_chevron_left"} />*/}
                        <span className="text-padding-top-7">Back to Diamond Search</span>
                    </div>
                  </Button>
                </div>
                <div className={classes.items_wrap}>
                  <DiamondOtherDetails diamondDetails={diamond.details} />
                </div>
              </div>
            </div>

        </div>
      </div>
      <DialogBox
        isOpen={isOpenDialog}
        isIcon={false}
        closeModal={closeDialogBox}
        title={"How do you want to finish your ring?"}
      >
        {dialogDetails.map((detail, index) => {
          return (
            <div
              className={classes.dialogBody}
              key={index}
              onClick={()=>{
                  detail.handleClick();
              }}
            >
              <div className={classes.dialogDetail}>
                <img src={detail.img} className={classes.dialogImg} alt="Customise your own" />
                <div className={classes.dialogText}>
                  <h2 className={classes.dialogTitle}>{detail.title}</h2>
                  <p className={classes.dialogParagraph}>{detail.paragraph}</p>
                </div>
              </div>
            </div>
          );
        })}
      </DialogBox>
      <StickyFooter instanceData={instanceData}/>
    </>
  );
};

export default connector(DiamondDetailsPage);
