import {FooterAction, FooterState} from "../types";
import FooterConstant from "../constant/FooterConstant";

const initialFilterState: FooterState = {
    toggle : false,
    stickyHeight:0,
    styleID: '',
    isShowFooter : false,
    isRedirectToEdit:false
};

const footerReducer = (state = initialFilterState, action: FooterAction): FooterState => {
    switch (action.type) {
        case FooterConstant.SET_FOOTER_TOGGLE :
            return {
                ...state ,
                toggle: !state.toggle
            }
        case FooterConstant.SET_FOOTER_HEIGHT :
            return {
                ...state ,
                stickyHeight:action.payload as number
            }
        case FooterConstant.IS_SHOW_FOOTER :
            return {
                ...state ,
                isShowFooter: action.payload as boolean
            }
        case FooterConstant.SET_STYLE_ID :
            return {
                ...state ,
                styleID: action.payload as string
            }
        case FooterConstant.SET_REDIRECT_EDIT_PAGE :
            return {
                ...state,
                isRedirectToEdit : action.payload as boolean
            }
        case FooterConstant.SET_INITIAL_FOOTER:
            return initialFilterState;
        default:
            return state;
    }
};

export default footerReducer;
