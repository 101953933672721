import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {PropsFromScreenManager, UseHeaderTabType} from "../../../types";

const UseHeaderTab = ({diamond,ring,setHeaderTabToSetting,setInitialOption,setDiamondTabIconTrue,setHeaderTabDefault,setSettingTabIconTrue,setIsShowFooter,instanceData:{screenManager},setInitialStartWithSettingData}:UseHeaderTabType & PropsFromScreenManager) => {
    const navigate = useNavigate();
    // const location = window.location;

    const setCenterTabData =(URLPath:string[])=>{
        if(URLPath.includes('#diamond')){
            if(!(Object.keys(ring.options).length >0)){
                setHeaderTabDefault();
            }else{
                setSettingTabIconTrue();
            }
        }else if(URLPath.includes('#setting')){
            if(URLPath.includes('diamondId')){
                setDiamondTabIconTrue('setting');
            }else{
                setHeaderTabToSetting('setting');
            }
        }else if(URLPath.includes('#dyo')){
            if(URLPath.includes('diamondId')){
                setDiamondTabIconTrue('dyo');
            }else{
                setHeaderTabToSetting('dyo');
            }
        }
    }

 /*   useEffect(() => {
        const URLPath =location.hash.split(/['/=']/);

        if(URLPath.includes('#diamond')){
            if(!(Object.keys(ring.options).length >0)){
                setHeaderTabDefault();
            }else{
                setSettingTabIconTrue();
            }
        }else if(URLPath.includes('#setting')){
            if(URLPath.includes('diamondId')){
                setDiamondTabIconTrue('setting');
            }else{
                setHeaderTabToSetting('setting');
            }
        }else if(URLPath.includes('#dyo')){
            if(URLPath.includes('diamondId')){
                setDiamondTabIconTrue('dyo');
            }else{
                setHeaderTabToSetting('dyo');
            }
        }
    }, [location]);*/


    useEffect(() => {
        const updatePathName:string[] = window.location.hash.split(/['/=']/);
        if(updatePathName){
            setCenterTabData(updatePathName);
        }
    }, [ring.options,diamond.details]);

    const handleChangePath =useCallback((key:string)=>{
        sessionStorage.removeItem('builder_mode');
        sessionStorage.removeItem('design_uuid');
        sessionStorage.removeItem('rpid');
        setInitialOption();
        setInitialStartWithSettingData();
        if(key === 'dyo'){
            sessionStorage.setItem('builder_mode','dyo');
            if(Object.keys(diamond.details).length > 0){
                setDiamondTabIconTrue('dyo');
                // navigate(`/ring-builder?styleId=RB001&diamondId=${diamond.details.id}`);
                (screenManager as any).changeScreen({ viewName: 'dyo', id: diamond.details.id,styleId: 'RB001' });

            }else{
                setHeaderTabToSetting('dyo');
                // navigate('/ring-builder?styleId=RB001');
                (screenManager as any).changeScreen({ viewName: 'dyo', id: null,styleId: 'RB001' });
            }
        }else{
            sessionStorage.setItem('builder_mode','setting');
            if(Object.keys(diamond.details).length > 0){
                setDiamondTabIconTrue('setting');
                // navigate(`/start-with-setting?diamondId=${diamond.details.id}`);
                (screenManager as any).changeScreen({ viewName: 'setting', id: diamond.details.id })
            }else{
                setHeaderTabToSetting('setting');
                // navigate('/start-with-setting');
                setIsShowFooter(false);
                (screenManager as any).changeScreen({ viewName: 'setting', id: null })
            }
            setInitialOption();
        }
    },[setHeaderTabToSetting,navigate,diamond])

    return {
        handleChangePath
    }
}

export default UseHeaderTab;