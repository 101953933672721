import {useCallback, useEffect, useRef} from "react";
import {PropsFromScreenManager, UseCMSPageProps} from "../../types";
import {HKCustomize, HKDiamond, HKRing} from "../Images";
import {SetEditableData} from "../../Utils/commonData";

const UseCMSPage = ({
                        filter,
                        setInitialOption,
                        setHeaderTabDefault,
                        setHeaderTabToSetting,
                        setInitialDiamondOption,
                        setInitialFilter,
                        setTableInitialState,
                        setInitialFooter,
                        setInitialHeader,
                        setInitialStartWithSettingData,
                        SetInitialTooltip,
                        instanceData:{screenManager},
                        setDiamondDetailsSuccess,
                        setExpandedRow,
                        setRingOptions,
                        setSelectedRingDetails,
                        setInitialCart,
                        setFilter
                    }: (UseCMSPageProps & PropsFromScreenManager)) => {

    const handleResetState = useCallback((view:string,styleId?:string|null) => {
        const builder_mode = sessionStorage.getItem('builder_mode');
        if(builder_mode !== "edit"){
            if (view === "diamond") {
                setHeaderTabDefault();
            } else if ((view === 'setting') || view === 'dyo') {
                setHeaderTabToSetting(view);
            }
            setInitialOption();
            setInitialDiamondOption();
            setInitialFilter();
            setTableInitialState();
            setInitialFooter();
            setInitialStartWithSettingData();
            SetInitialTooltip();
            setInitialCart();
        }
        if (
            screenManager &&
            (screenManager as any).changeScreen
        ) {
            (screenManager as any).changeScreen({
                viewName: view,
                styleId:styleId || null
            });
        }
    }, [setInitialOption, setInitialDiamondOption, setInitialFilter, setTableInitialState, setHeaderTabToSetting, setHeaderTabDefault,setInitialHeader,setInitialFooter,setInitialStartWithSettingData,SetInitialTooltip])

    useEffect(() => {
        const handleHashChange = () => {
            const rpid = sessionStorage.getItem('rpid');
            const builder_mode = sessionStorage.getItem('builder_mode');
            const uuid = sessionStorage.getItem('design_uuid');

            if (rpid && uuid && builder_mode){
                SetEditableData(uuid,setDiamondDetailsSuccess,setExpandedRow,setRingOptions,setSelectedRingDetails,setFilter);
                    (screenManager as any).changeScreen({
                        viewName: 'dyo',
                        styleId:rpid
                    })
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const ContentArray = [
        {
            linkTO: "diamond",
            styleId:null,
            imagePath: HKDiamond,
            onClick: handleResetState,
            imageAlt: "Start With a Diamond",
            heading: "Start With a Diamond",
            paragraph: `Choose your diamond’s every detail — from size, shape and quality to origin, price and more. Finish with an existing setting or continue customizing until it’s just right for your unique engagement ring.`,
        },
        {
            linkTO: "setting",
            styleId:null,
            onClick: handleResetState,
            imagePath: HKRing,
            imageAlt: "Start With a Setting",
            heading: "Start With a Setting",
            paragraph: `Select a ring setting from our exclusive curation. You can then continue customizing, which includes choosing your favorite diamond, until you’re totally in love with your custom engagement ring. `,
        },
        {
            // linkTO: "/dyo?styleId=RB001",
            linkTO: "dyo",
            styleId:"RB001",
            onClick: handleResetState,
            imagePath: HKCustomize,
            imageAlt: "Design Your Own",
            heading: "Design Your Own",
            paragraph: `Design your own engagement ring by customizing your ring’s style, setting, metal and so much more. You’ll then complete your design with the diamond that meets your specifications (aka your dream diamond).`,
        },
    ];

    useEffect(() => {
        if (screenManager) {
            (screenManager as any).props.setStateManagerData(
                'currentScreen',
                'CMS Screen',
            );
            (screenManager as any).props.setStateManagerData(
                'currentView',
                'Main View',
            );
        }
    }, []);
    return {
        ContentArray
    }
}

export default UseCMSPage;
