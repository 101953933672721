import {useCallback, useEffect, useState} from 'react';
import { UseCartType } from '../../types';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';
import {colorName, DiamondLabels} from '../../Utils/commonData';
import ShortUniqueId from 'short-unique-id';
import {compareObjects} from "../../Utils/compareObjects";

const useCart = (props: UseCartType) => {
  const {
    ring,
    footer,
    filter,
    diamond,
    table,
    setFooterToggle,
    setRedirectEditPage,
    setSettingTabIconTrue,
    setDiamondTabIconTrue,
    setCartImageSuccess,
    setCartThumbImageSuccess,
    settingFilter,
    stateManger,
    instanceData: { screenManager },
  } = props;
  const [isShowDiamondType, setShowDiamondType] = useState(false);
  const [isShowRingType, setShowRingType] = useState(false);
  const [isShowDoubleFirstBandType, setIsShowDoubleFirstBandType] = useState(false);
  const [isShowDoubleSecondBandType, setIsShowDoubleSecondBandType] = useState(false);
  const [image,setImage] = useState<string[]>([]);
  const [thumbImage,setThumbImage] = useState<string[]>([]);
  const {
    source,
    carat,
    shape,
    cut,
    color,
    clarity,
    measurement,
    table_per,
    depth,
    symmetry,
    polish,
    gridle,
    culet,
    fluorecence,
    diamond_price,
  } = diamond.details;
  const { Ring_Crown, Ring_Side, Ring_Style, Color: ringColor, Metal, Ring_Size, Engraving } = ring.selectedRingDetails;
  const { Single_First_Band, Double_First_Band, Double_Second_Band, First_Band_Engraving, Second_Band_Engraving } =
    ring.options;

  useEffect(()=>{
    const { Ring_Style, Ring_Crown, Center_Diamond_Size,Ring_Side, Diamond_Shape, Color, Single_First_Band, Double_First_Band, Double_Second_Band,Wedding_Band } = ring.options;
    setImage([]);
    setThumbImage([]);
    if(footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side){
      const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-200-${Ring_Side}` : `${footer.styleID}-PKRCLP-RND-200-PRG`;
      ["V1", "V2", "V3", "V4"].forEach((view: string) => {
        const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}-${Single_First_Band || Double_First_Band || Color }-${Double_Second_Band || Color }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/2000/fafafa/random.jpg`;
        const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${Color}-${Color}-${Single_First_Band || Double_First_Band || Color }-${Double_Second_Band || Color }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/${view}/150/fafafa/random.jpg`;
        setImage((prev: string[]) => {
          return [...prev, imagePath];
        });
        setThumbImage((prev: string[]) => {
          return [...prev, thumbPath];
        });
      });
    }
  },[ring.options])
  useEffect(()=>{
    setCartImageSuccess(image);
  },[image])

  useEffect(()=>{
    setCartThumbImageSuccess(thumbImage);
  },[thumbImage])

  const showDiamondDetails = {
    'Diamond Type': source,
    'Carat Weight': carat,
    Shape: shape,
    Cut: cut,
    Color: color,
    Clarity: clarity,
    Measurements: measurement,
    Table: `${table_per ? `${table_per}%` : ''}`,
    Depth: `${depth ? `${depth}%` : ''}`,
    Symmetry: symmetry,
    Polish: polish,
    Gridle: gridle,
    Culet: culet,
    Fluorecence: fluorecence,
  };
  const showRingDetails = {
    'Ring Style': Ring_Style,
    'Ring Crown': Ring_Crown,
    'Ring Side': Ring_Side,
    Metal: Metal,
    Color: ringColor,
    'Ring Size': Ring_Size,
    Engraving: Engraving,
  };

  const FirstBand = {
    color: colorName[Double_First_Band] || colorName[Single_First_Band],
    engraving: First_Band_Engraving,
  };
  const SecondBand = {
    color: colorName[Double_Second_Band],
    engraving: Second_Band_Engraving,
  };

  const handleDiamondAction = () => {
    setShowDiamondType((type) => !type);
  };
  const handleRingAction = () => {
    setShowRingType((type) => !type);
  };

  const handleReviewClick= async() =>{
    const rpid = JSON.parse(sessionStorage.getItem('rpid') as string);
    const builder_mode = JSON.parse(sessionStorage.getItem('builder_mode') as string);
    const uuid = JSON.parse(sessionStorage.getItem('design_uuid') as string);
    const endpoint = process.env.REACT_APP_DESIGN_DATA_UPLOAD as string;
    const uid = new ShortUniqueId({ length: 10 });

    const newName = generateName();
    const {Ring_Style,Ring_Side,Diamond_Shape,Center_Diamond_Size,Metal,Color,Diamond_Type,Wedding_Band} = ring.options;
    const ringsku = `${footer.styleID}-${Ring_Style}${Ring_Side}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Style}-${Metal}-${Color}-${Diamond_Type}`;
    const {diamond_details,setting_details,ring_options_code,name,sku,diamond_filter_options,setting_filter_options,ring_b2c_price,ring_b2b_cost,table_row_id} = stateManger.editData ;
    const oldEditData = {
      diamond_details, setting_details,ring_options_code,sku,diamond_filter_options,setting_filter_options,ring_b2b_cost,table_row_id
    }
    const newEditData = {
      diamond_details: diamond.details,
      setting_details: ring.selectedRingDetails,
      ring_options_code:ring.options,
      sku:ringsku,
      diamond_filter_options:filter.options,
      setting_filter_options:settingFilter.options,
      ring_b2b_cost:-1,
      table_row_id:table.expandRow,
    }
    const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-200-${Ring_Side}` : `${footer.styleID}-PKRCLP-RND-200-PRG`;
    const isSameObjects = compareObjects(oldEditData,newEditData);
    let returnUrl:string;
    if(!isSameObjects){
      const result = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          ...newEditData,
          name:newName,
          image_code:`${style}/${Color}-${Color}-${Single_First_Band || Double_First_Band || Color }-${Double_Second_Band || Color }/${Wedding_Band === "Double" ? 'C1': Wedding_Band === "Single" ? 'C2': 'C3'}/`,
          ring_b2c_price:+ring.ringPrice,
          action:builder_mode === 'edit'? 'update' :'insert',
          ...(builder_mode === 'edit') && { short_uuid: uuid},
        }),
      })
      if(result.ok){
        let design_data ;
        design_data = await result.json();
        if(design_data){
          if(builder_mode === 'build'){
            returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}&pid=${rpid}&hkuuid=${await design_data.short_uuid}` ;
          }
          else if(builder_mode === 'edit'){
            const uuid = JSON.parse(sessionStorage.getItem('design_uuid') as string);
              returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}&pid=${rpid}&hkuuid=${uuid}&action=updated`;
          }
        }
      }
    }
    if(isSameObjects) {
      returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}&pid=${rpid}&hkuuid=${uuid}`;
    }
    ['rpid','builder_mode','design_uuid'].forEach(key =>sessionStorage.removeItem(key));
    // @ts-ignore
    window.location.href = returnUrl;
  }
  const generateName = useCallback(()=>{
    const {Diamond_Type, Diamond_Shape, Ring_Style, Color, Metal} = ring.selectedRingDetails;
    let DiamondSize = ring.optionsData.Center_Diamond_Size && ring.optionsData.Center_Diamond_Size.find((size: any) => size.Code === ring.options.Center_Diamond_Size);
    let centerDiamondSize =  (diamond.details && diamond.details.carat) ? `${diamond.details.carat} ct` : DiamondSize !== undefined ? `${DiamondSize.Code/100} ct` : `${ring.options.Center_Diamond_Size} ct`;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      return `${Ring_Style} ${Diamond_Type} Diamond ${DiamondLabels[Diamond_Shape as string]} Engagement Ring in ${(Metal === 'Platinum') ? Metal : `${Metal.split(' ')[0]} ${Color} ${Metal.split(' ')[1]}`} (${centerDiamondSize}. tw.)${ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`;
    }
  },[ring.selectedRingDetails,ring.options,ring.optionsData])
  const editDiamondAction = useCallback(() => {
    setFooterToggle();
    setSettingTabIconTrue();
    (screenManager as any).changeScreen({ viewName: 'diamond', id: null });
  }, [setFooterToggle, setSettingTabIconTrue, screenManager]);

  const editRingAction = useCallback(() => {
    setFooterToggle();
    setDiamondTabIconTrue('dyo');
    setRedirectEditPage(true);
    if (Object.keys(diamond.details).length > 0) {
      (screenManager as any).changeScreen({
        viewName: 'dyo',
        styleId: footer.styleID,
        id: diamond.details.id,
      });
    } else {
      (screenManager as any).changeScreen({
        viewName: 'dyo',
        styleId: footer.styleID,
        id: null,
      });
    }
  }, [setFooterToggle, setDiamondTabIconTrue, setRedirectEditPage, screenManager, diamond.details]);

  const handleDoubleFirstBandAction = useCallback(() => {
    setIsShowDoubleFirstBandType((type) => !type);
  }, [setIsShowDoubleFirstBandType]);

  const handleDoubleSecondBandAction = useCallback(() => {
    setIsShowDoubleSecondBandType((type) => !type);
  }, [setIsShowDoubleSecondBandType]);

  const allDetails = [
    {
      showDetails: Object.keys(diamond.details).length > 0 ? showDiamondDetails : [],
      title: `${carat} ct ${DiamondLabels[shape as string]} ${source} Diamond`,
      price: `$${ToLocalStringConvert(diamond_price ? +diamond_price : 0)}`,
      editAction: null/*editDiamondAction*/,
      moreAction: handleDiamondAction,
      isShowMore: isShowDiamondType,
    },
    {
      showDetails: Object.keys(ring.selectedRingDetails).length > 0 ? showRingDetails : [],
      title: `${Ring_Style} ${source} Diamond ${DiamondLabels[shape as string]}
      ${
        footer.styleID?.includes('RB') ? `${Ring_Crown} ${Ring_Side}` : ''
      } ${(Metal === 'Platinum' && Metal) ? Metal:`${Metal && Metal.split(' ')[0]} ${ringColor} ${Metal && Metal.split(' ')[1]}`} Ring`,
      price: `$${ToLocalStringConvert(ring.ringPrice)}`,
      editAction: null/*editRingAction*/,
      moreAction: handleRingAction,
      isShowMore: isShowRingType,
    },
    {
      showDetails: Double_First_Band || Single_First_Band ? FirstBand : [],
      title:`${Ring_Style} ${source} Diamond Wedding Band in
      ${(Metal === 'Platinum' && Metal) ? Metal:`${Metal && Metal.split(' ')[0]} ${FirstBand.color} ${Metal && Metal.split(' ')[1]}`}`,
      price: ``,
      editAction: null,
      moreAction: handleDoubleFirstBandAction,
      isShowMore: isShowDoubleFirstBandType,
    },
    {
      showDetails: Double_Second_Band ? SecondBand : [],
      title: `${Ring_Style} ${source} Diamond Wedding Band in
      ${(Metal === 'Platinum') ? Metal:`${Metal && Metal.split(' ')[0]} ${SecondBand.color} ${Metal && Metal.split(' ')[1]}`}`,
      price: ``,
      editAction: null,
      moreAction: handleDoubleSecondBandAction,
      isShowMore: isShowDoubleSecondBandType,
    },
  ];

  return {
    allDetails,
    Ring_Style,
    diamond_price,
    handleReviewClick,
    
  };
};

export default useCart;
