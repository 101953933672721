import React, {useCallback, useState} from "react";
import {useMediaQuery} from "react-responsive";
import classes from "../Header/Header.module.css";
import Icon from "../Icons";


const UseExitButton = () => {
    const [isOpenDialog, stIsOpenDialog] = useState<boolean>(false);
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const isLargeTablet = useMediaQuery({query: "(max-width: 1079px)"});

    const closeDialogBox = useCallback(() => {
        stIsOpenDialog(false);
    }, [stIsOpenDialog])

    const openDialogBox = useCallback(() => {
        stIsOpenDialog(true);
    }, [stIsOpenDialog]);

    const onClickMenuHandle = useCallback(() => {
        setMobileMenu((enabled) => !enabled);
    }, [setMobileMenu])

    const renderMenuContent = useCallback((): JSX.Element | null => {
        if (isLargeTablet) {
            return (
                <div className={classes.menuMobile} onClick={onClickMenuHandle}>
                    <Icon name={"hk_menu"}/>
                </div>
            );
        }
        return null;
    }, [isLargeTablet]);

    return {
        mobileMenu,
        isOpenDialog,
        openDialogBox,
        closeDialogBox,
        renderMenuContent

    }
}

export default UseExitButton;