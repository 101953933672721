class RouteManager {
  static findPath(route: string) {

    switch (true) {
      case route === '#home':
        return './components/CMSPage/CMSScreen';
      case route === 'edit':
        return './components/CMSPage/CMSScreen';
      case route && route.startsWith('#details'):
        return './components/DiamondDetailsPage/DiamondDetailsScreen';
      case route === '#diamond' || route === '#select-diamond':
        return './components/Diamond/DiamondScreen';
      case route === '#setting':
        return './components/StartWithSetting/StartWithSettingScreen';
      case route === '#dyo':
        return './components/BuildOwn/BuildOwnScreen';
      default:
        return './components/CMSPage/CMSScreen';
    }
    
  }

  
}

export default RouteManager;
