import HeaderConstant from "../constant/HeaderConstant";

export const setHeaderTabDefault = () => ({
    type: HeaderConstant.SET_HEADER_TAB_TO_DEFAULT
})

export const setHeaderTabToSetting = (value:string) => ({
    type: HeaderConstant.SET_HEADER_TAB_TO_SETTING_OWN,
    payload:value
})

export const setDiamondTabIconTrue = (value:string) => ({
    type: HeaderConstant.SET_HEADER_TAB_DIAMOND_ICON,
    payload:value
})

export const setSettingTabIconTrue = () => ({
    type: HeaderConstant.SET_HEADER_TAB_SETTING_ICON
})

export const setInitialHeader = ()=>({
    type: HeaderConstant.SET_INITIAL_HEADER
})