import React, { FC } from "react";
import { ButtonProps } from "../../types";
import classes from "./Button.module.css";

const Button: FC<ButtonProps> = (props) => {
  const { type, buttonType, children, className, onClick,isDisabled } = props;
  return (
    <>
      <button
        type={type}
        onClick={onClick}
        disabled={isDisabled}
        className={`${classes.root} ${
          buttonType ? classes[buttonType] : ""
        }  ${isDisabled ? classes.isDisabled : ''} ${className} `}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
