import {diamondValue} from "../types";

export const compareTwoDiamondValue = (diamond1:diamondValue,diamond2:any) => {
  // Compare source
  if (diamond1.source !== diamond2.source) {
    return false;
  }

  // Compare carat
  if (diamond1.carat !== diamond2.carat) {
    return false;
  }

  // Compare shape (order-independent)
  if(!diamond1.shape || !diamond2.shape){
    return false ;
  }

  if (diamond1.shape.length !== diamond2.shape.length) {
    return false;
  }

  const sortedShape1 = diamond1.shape.slice().sort();
  const sortedShape2 = diamond2.shape.slice().sort();

  for (let i = 0; i < sortedShape1.length; i++) {
    if (sortedShape1[i] !== sortedShape2[i]) {
      return false;
    }
  }

  return true;
}
