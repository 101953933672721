import React, { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DialogBoxProps } from "../../types";
import Button from "../Button";
import Icon from "../Icons";
import classes from "./DialogBox.module.css";

const DialogBox: FC<DialogBoxProps> = ({
  isOpen,
  closeModal,
    isIcon,
  children,
  title,
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={classes.root} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={classes.mask} />
          </Transition.Child>

          <div className={classes.container}>
            <div className={classes.from}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={classes.panel}>
                  <Dialog.Title as="h1" className={classes.title}>
                    {title}
                    {isIcon && <Button
                        type={'button'}
                        className={classes.closeIcon}
                        onClick={closeModal}
                    >
                      <Icon name={'hk_xmark'}/>
                    </Button>}
                  </Dialog.Title>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DialogBox;
