import React from 'react';
import { PropsFromScreenManager } from '../../types';
import BuildOwn from './BuildOwn';
import StickyFooter from '../StickyFooter';
import Header from '../Header';
import Screen from '../../Screen';

class BuildOwnScreen extends Screen {
  constructor(props: PropsFromScreenManager) {
    super(props);
  }

  render() {
    return <BuildOwn instanceData={this.props.instanceData} />;
  }
}

export default BuildOwnScreen;
