import {allSwatchValue, PDPBuilderType, PropsFromScreenManager, RingSize, TableData} from "../../types";
import {useCallback, useEffect, useRef, useState} from "react";
import {iFrameHost} from "../BuildOwn/BuildOwn";
import {useNavigate} from "react-router-dom";
import {propertyMappings, source} from "../Diamond/useDiamond";
import {TooltipDataFetch} from "../Tooltip/TooltipDataFetch";
import {buildYourOwnValue, findNearestValue, startWithSettingValue} from "../../Utils/findNearestCaratValue";
import {getRingPrice} from "../../Utils/getRingPriceValue";
import {getDiamondCode} from "../../Utils/getDiamondCode";
import {DiamondLabels} from "../../Utils/commonData";

const usePdpBuilder = (props:PDPBuilderType & PropsFromScreenManager) => {
  const {
    ring,
    diamond,
    footer,
    settingFilter,
    setSelectedRingDetails,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setRingOptions,
    setOptionsLoading,
    setOptionsData,
    setOptionsFail,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    SetTooltipDataSuccess,
    setTooltipDataLoading,
    SetTooltipDataError,
    setStyleID,
    setIframeLoadActionCount,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setExpandedRow,
    setBandTabIndex,
    setIsShowFooter,
    setFooterToggle,
    setAfterRingOptions,
    setSettingTabIconTrue,
    instanceData: { screenManager },
  } = props;

  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const match_styleId = currentUrl.match(/styleId=([^&]*)/);
  const match_diamondId = currentUrl.match(/diamondId=([^&]*)/);
  const styleId = match_styleId?.[1].split('/')[0] || '';
  const diamondId = match_diamondId?.[1] || '';

  const [productName, setProductName] = useState<string>('')
  const hasEffectRun = useRef(false);
  const [fieldValidation,setFieldValidation] = useState<string[]>([])

  useEffect(() => {
    const {Diamond_Type, Diamond_Shape, Ring_Style, Color, Metal} = ring.selectedRingDetails;
    let DiamondSize = ring.optionsData.Center_Diamond_Size && ring.optionsData.Center_Diamond_Size.find((size: any) => size.Code === ring.options.Center_Diamond_Size);
    let centerDiamondSize =  (diamond.details && diamond.details.carat) ? `${diamond.details.carat} ct` : DiamondSize !== undefined ? `${DiamondSize.Code/100} ct` : `${ring.options.Center_Diamond_Size} ct`;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      setProductName(`${Ring_Style} ${Diamond_Type} Diamond ${DiamondLabels[Diamond_Shape as string]} Engagement Ring in ${(Metal === 'Platinum') ? Metal : `${Metal.split(' ')[0]} ${Color} ${Metal.split(' ')[1]}`} (${centerDiamondSize}. tw.) ${ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`)
    }
  }, [ring, ring.options,diamond,diamond.details])
  useEffect(() => {
    setStyleID(styleId as string)
  }, [styleId])
  useEffect(() => {
    Object.entries(ring.options).map(([key, value]) => {
      if (ring.optionsData) {
        const allValue = ring.optionsData[key];
        if (allValue) {
          const findSwatchValue = (allValue as allSwatchValue[]).find(
            (a) => a.Code === value
          );
          if (findSwatchValue) {
            setSelectedRingDetails(
              key,
              findSwatchValue.Name as string
            );
          }
        }
      }
    });
    if ("Engraving" in ring.options) {
      setSelectedRingDetails("Engraving", ring.options["Engraving"])
    }

  }, [ring.options]);
  useEffect(()=>{
    if(Object.keys(ring.options).length > 0){
      const { Ring_Style, Ring_Side, Metal, Color } = ring.options;
      if(Ring_Style && Ring_Side && Metal && Color){
        const {Ring_Crown,Diamond_Shape,Center_Diamond_Size,Diamond_Type,Wedding_Band} = ring.options;
        const ringPriceData = `${styleId}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${Metal}-${Color}-${Diamond_Type}`;
        const priceData = {
          sku: `'${ringPriceData}'` ,
          order_items: Wedding_Band === "No Band" ? 1 : Wedding_Band === "Single" ? 2 : 3
        }
        getRingPrice(setPriceLoading,setRingPrice,setRingPriceError, priceData);
      }
    }
  },[ring.options["Ring_Style"],ring.options["Ring_Crown"],ring.options["Diamond_Shape"],ring.options["Center_Diamond_Size"],ring.options["Ring_Side"],ring.options["Metal"],ring.options["Color"],ring.options["Diamond_Type"],ring.options["Wedding_Band"]])
  const [pendingEvents, setPendingEvents] = useState<{ [key: string]: string | boolean }[]>([]);
  const iframeAction = useRef(pendingEvents);

  useEffect(() => {
    iframeAction.current = pendingEvents;
  }, [pendingEvents])

  useEffect(() => {
    const afterDesignLoaded = ["ChangeRingColor", "LeftBand", "RightBand", "ChangeLeftBandColor", "ChangeRightBandColor"];
    let isHandShakeReturn = false;

    const handleMessageEvent = (event: MessageEvent<any>) => {
      //@ts-ignore
      const origin = event.origin || event?.originalEvent.origin;
      if (origin === window.location.origin) {
        if (event.data.action === 'HandshakeIntegration') {
          window.postMessage({
            action: "HandshakeInternal"
          });
        }
        if (event.data.action === 'HandshakeReturnInternal') {
          isHandShakeReturn = true;
        }
        if (isHandShakeReturn) {
          iframeAction.current.filter(model => !afterDesignLoaded.includes(model.action as string)).forEach((data) => {
            window.postMessage(data);
          });
          isHandShakeReturn = false;
        }
      }
      if (origin === iFrameHost) {
        if (event.data.action === 'DesignLoaded') {
            setIframeLoadActionCount(count => {
              const updatedCount = count - 1;
              if (updatedCount === 0) {
                iframeAction.current.filter(model => afterDesignLoaded.includes(model.action as string)).forEach((data) => {
                  window.postMessage(data);
                });
                window.postMessage({
                  action: 'SwitchCameraView'
                })
              }
              return updatedCount;
            });
          }
        }
      if(event.data.action === 'DesignLoadError'){
        setIframeLoadActionCount(0);
      }
        /*if(event.data.action === "DownloadFile"){
          set3DImageSuccess(event.data.imageUrl);
        }*/
      };
    if(iframeAction.current.length > 0) {
      window.addEventListener("message", handleMessageEvent);
      window.postMessage({
        action: "HandshakeInternal"
      });
    }
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [iframeAction.current]);
  const handle3DObjectView = useCallback((key: string, value: string | boolean) => {
    setPendingEvents((prev) => {
      // Create a new array with the updated value
      const updatedPendingEvents = [...prev, { action: key, value }];
      // Update the iframeAction.current ref with the new array reference
      iframeAction.current = updatedPendingEvents;
      return updatedPendingEvents;
    });

  }, [setPendingEvents]);

  const optionsData = useCallback(async (selectedDiamond: { [key: string]: string }, shape: string | null) => {
    setOptionsLoading();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PDP_OPTIONS_KEY}?ring_builder=${styleId}`
      );
      if (!response.ok) {
        setOptionsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result = await response.json();
      const {
        Diamond_Type,
        Diamond_Shape,
        Ring_Crown,
        Ring_Side,
        Ring_Style,
        Color,
        Metal,
        Ring_Size,
        Center_Diamond_Size
      } = result;

      const newResult = {
        Ring_Style,
        Diamond_Shape,
        Diamond_Type,
        Center_Diamond_Size,
        Ring_Crown,
        Ring_Side,
        Color,
        Metal,
        Ring_Size
      };
      setOptionsData(newResult);
      const rpid = sessionStorage.getItem('rpid');
      const builder_mode = sessionStorage.getItem('builder_mode');
      const uuid = sessionStorage.getItem('design_uuid');
      const modelInitialData: { [key: string]: string } = {};
      if((!(rpid  && uuid) && builder_mode !== "edit")){
        Object.entries(newResult).forEach(([key, value]) => {
          if (["Ring_Style", "Ring_Crown", "Center_Diamond_Size", "Ring_Side"].includes(key)) {
            modelInitialData[`Initial_${key}`] = key === "Center_Diamond_Size"
              ? (selectedDiamond["Center_Diamond_Size"] || (styleId.includes('RP') ? ring.options[key] || settingFilter.options[key] || "200"  :  ring.options[key] || value[0].Code))
              : (ring.options[key] || settingFilter.options[key] || value[0].Code)
          }
          if(key !== "Ring_Size"){
            switch (key) {
              case "Color":
                handle3DObjectView('ChangeRingColor', ring.options[key] || settingFilter.swatchColor || settingFilter.options[key] || value[0].Code);
                break;
              case 'Diamond_Shape':
                modelInitialData[`Initial_${key}`] = ring.options[key] || selectedDiamond[key] || settingFilter.options[key] || value[0].Code;
                break;
            }
            if (!Object.keys(selectedDiamond).includes(key)) {
              const defaultValue =
                key === "Center_Diamond_Size"
                  ? (styleId.includes('RP') ? ring.options[Center_Diamond_Size] || settingFilter.options.Center_Diamond_Size || "200" : value[0].Code)
                  : key === "Color" ? (ring.options["Color"] || settingFilter.swatchColor || settingFilter.options["Color"] || value[0].Code)
                  : Object.keys(settingFilter.options).includes(key)
                    ? ring.options[key] || settingFilter.options[key]
                        : (key === 'Diamond_Type' && Object.keys(settingFilter.options).includes('Center_Diamond_Type'))
                        ? settingFilter.options['Center_Diamond_Type']
                    : value[0].Code;
              return setRingOptions(key, defaultValue);
            }
          }
        });
        const {
          Initial_Ring_Style,
          Initial_Diamond_Shape,
          Initial_Center_Diamond_Size,
          Initial_Ring_Crown,
          Initial_Ring_Side,
        } = modelInitialData;
        handle3DObjectView('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}`:Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}${styleId.includes('RP') ? `/${Initial_Ring_Side}`: ''}/head`);
        handle3DObjectView('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}`:Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}/${Initial_Ring_Side}/shank`);
        if(styleId.includes('RB')){
          handle3DObjectView('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Initial_Ring_Style}${Initial_Ring_Crown}`:Initial_Ring_Crown}/${Initial_Diamond_Shape}/${Initial_Center_Diamond_Size}/${Initial_Ring_Side}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        setIframeLoadActionCount(count => count + 2);

        setRingOptions('Wedding_Band', 'No Band');
      }
    } catch (e) {
      setOptionsFail(e);
    }
  }, [setOptionsLoading, setOptionsFail, setOptionsData, fetch, setRingOptions, handle3DObjectView,settingFilter.options]);

  const handlePDPOptions = useCallback((key: string, value: string) => {
    setRingOptions(key, value);
  }, [setRingOptions]);

  const selectedData = useCallback(async (diamondDetail: { [key: string]: string }, carat: string) => {
    const result = await getDiamondCode(diamondDetail,setSelectedDiamondLoading,setSelectedDiamondFail);
    if (result) {
      setSelectedDiamondData({...result, Center_Diamond_Size: findNearestValue(+carat)});
      optionsData({...result, Center_Diamond_Size: findNearestValue(+carat)}, diamondDetail.shape);
    }
  }, [URLSearchParams, setSelectedDiamondLoading, setSelectedDiamondData, setSelectedDiamondFail, optionsData, fetch]);
  const DetailsData = useCallback(async (id: string) => {
    setDiamondDetailsLoading();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/?id='${id}'`);
      if (!response.ok) {
        setDiamondDetailsFail(new Error(`HTTP error! Status: ${response.status}`));
      }
      const result: TableData = await response.json();

      Object.keys(propertyMappings).forEach((property: string) => {
        result[property] = propertyMappings[property][result[property] as number - 1];
      });
      const updatedResult: TableData = {
        ...result,
        source: result.source === 'HK-ND' ? source[0] : source[1],
        diamond_price: result.diamond_price !== null ? `${result.diamond_price}` : '-'
      }
      setDiamondDetailsSuccess(updatedResult);
      setIsShowFooter(true);
      if (updatedResult) {
        const {shape, source, carat} = updatedResult;
        const details = {shape: shape as string, type: source as string};
        selectedData(details, carat as string);
      }
    } catch (e) {
      setDiamondDetailsFail(e);
    }
  }, [diamondId, setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail, selectedData, fetch]);

  const handleAllOptions = useCallback((key: string, value: any) => {
    const {
      Center_Diamond_Size,
      Diamond_Shape,
      Ring_Crown,
      Ring_Side,
      Ring_Style,
      Wedding_Band
    } = ring.options;
    const CenterDiamondSize = ring.optionsData.Center_Diamond_Size.find((diamond: any) => (key === 'Center_Diamond_Size') ? (diamond.Code === value.Code) : (diamond.Code === Center_Diamond_Size));
    if (key === "Metal" && value.Code === "PT") {
      handlePDPOptions('Color', 'WG');
      if (Wedding_Band === "Single") {
        handlePDPOptions(`${Wedding_Band}_First_Band`, 'WG');
      }
      if (Wedding_Band === "Double") {
        handlePDPOptions(`${Wedding_Band}_First_Band`, 'WG');
        handlePDPOptions(`${Wedding_Band}_Second_Band`, 'WG');
      }
    }
    setPendingEvents([]);
    switch (key) {
      case 'Ring_Style':
        sendMessage('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${value.Code}${Ring_Crown}`:Ring_Crown}/${Diamond_Shape}/${CenterDiamondSize.Code}${styleId.includes('RP') ? `/${Ring_Side}`: ''}/head`);
        sendMessage('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${value.Code}${Ring_Crown}`:Ring_Crown}/${Diamond_Shape}/${CenterDiamondSize.Code}/${Ring_Side}/shank`);
        setIframeLoadActionCount(count => count + 2);
        if (Wedding_Band !== 'No Band' && styleId.includes('RB')) {
          sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${value.Code}${Ring_Crown}`:Ring_Crown}/${Diamond_Shape}/${CenterDiamondSize.Code}/${Ring_Side}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        bands3DModel();
        break;
      case 'Diamond_Shape':
      case 'Center_Diamond_Size':
      case 'Ring_Crown':
        sendMessage('LoadHead', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${key === 'Ring_Crown' ? value.Code : Ring_Crown}`: key === 'Ring_Crown' ? value.Code : Ring_Crown}/${(key === 'Diamond_Shape' ? value.Code : Diamond_Shape)}/${CenterDiamondSize.Code}${styleId.includes('RP') ? `/${Ring_Side}`: ''}/head`);
        sendMessage('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${key === 'Ring_Crown' ? value.Code : Ring_Crown}`: key === 'Ring_Crown' ? value.Code : Ring_Crown}/${(key === 'Diamond_Shape' ? value.Code : Diamond_Shape)}/${CenterDiamondSize.Code}/${Ring_Side}/shank`);
          setIframeLoadActionCount(count => count + 2);
        if (Wedding_Band !== 'No Band' && styleId.includes('RB')) {
          sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${key === 'Ring_Crown' ? value.Code : Ring_Crown}`: key === 'Ring_Crown' ? value.Code : Ring_Crown}/${(key === 'Diamond_Shape' ? value.Code : Diamond_Shape)}/${CenterDiamondSize.Code}/${Ring_Side}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        bands3DModel();
        break;
      case 'Ring_Side':
        sendMessage('LoadShank', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}`: Ring_Crown}/${Diamond_Shape}/${CenterDiamondSize.Code}/${value.Code}/shank`);
        setIframeLoadActionCount(count => count + 1);
        if (Wedding_Band !== 'No Band' && styleId.includes('RB')) {
          sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}`: Ring_Crown}/${Diamond_Shape}/${CenterDiamondSize.Code}/${value.Code}/bands`);
          setIframeLoadActionCount(count => count + 1);
        }
        bands3DModel();
        break;
      case 'Color':
        sendMessage('ChangeRingColor', (value.Code === 'PT') ? 'WG' : value.Code);
        break;
      case 'Metal':
        if (value.Code === 'PT') {
          sendMessage('ChangeRingColor', 'WG');
        }
        break;
      default:
        break;
    }
    handlePDPOptions(key, value.Code);
  }, [handlePDPOptions, ring.options,ring.optionsData]);
  const bands3DModel = useCallback(() => {
    const {Color, Wedding_Band, Single_First_Band, Double_First_Band, Double_Second_Band} = ring.options;
    const pendingValues: { [key: string]: boolean | string } = {};
    if (Color) pendingValues['ChangeRingColor'] = Color;
    if (Wedding_Band !== 'No Band') {
      if (Wedding_Band === "Single" || Wedding_Band === "Double") {
        pendingValues['ChangeLeftBandColor'] = Wedding_Band === "Single" ? Single_First_Band : Double_First_Band;
        pendingValues['LeftBand'] = true;
        if (Wedding_Band === "Double") {
          pendingValues['ChangeRightBandColor'] = Double_Second_Band;
          pendingValues['RightBand'] = true;
        }
      }
    }
    const pendingRingSideEvents: { [key: string]: boolean | string }[] = Object.entries(pendingValues).map(([key, value]) => {
      return {action: key, value}
    })
    setPendingEvents(pendingRingSideEvents)
  }, [ring.options, setPendingEvents])

  function sendMessage(action: string, value: string | boolean) {
    window.postMessage({action, value});
  }
  const isEditableRingOptions = useRef(false);
  useEffect(() => {
    //edit code
    if (Object.keys(ring.options).length > 0) {
      isEditableRingOptions.current = true;

      const {
        Ring_Style,
        Ring_Crown,
        Diamond_Shape,
        Center_Diamond_Size,
        Ring_Side,
        Wedding_Band,
        Single_First_Band,
        Double_First_Band,
        Double_Second_Band,
        First_Band_Engraving,
        Second_Band_Engraving,
        Engraving,
        Color
      } = ring.options;

      const styleIdPrefix = styleId && styleId.includes('RB') ? `${Ring_Style}${Ring_Crown}` : Ring_Crown;
      const bandTabIndex = Wedding_Band === 'Single' ? 1 : Wedding_Band === 'Double' ? 2 : 0;
      const bandColor = Wedding_Band === 'Single' ? Single_First_Band : Wedding_Band === 'Double' ? Double_First_Band : null;

      const pendingValues: { [key: string]: boolean | string } = {
        'LoadHead': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}${styleId.includes('RP') ? `/${Ring_Side}` : ''}/head`,
        'LoadShank': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/shank`,
        'LoadBand': `${styleId}/${styleIdPrefix}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`,
        'ChangeRingColor': Color
      };

      if (Wedding_Band !== 'No Band') {
        if (Wedding_Band === "Single" || Wedding_Band === "Double") {
          setBandTabIndex(bandTabIndex);
          pendingValues['ChangeLeftBandColor'] = bandColor as string;
          pendingValues['LeftBand'] = true;
          if (Wedding_Band === "Double") {
            setBandTabIndex(bandTabIndex);
            pendingValues['ChangeRightBandColor'] = Double_Second_Band;
            pendingValues['RightBand'] = true;
          }
        }
      }

      if (Engraving) pendingValues['ShankEngraving'] = Engraving;
      if (First_Band_Engraving) pendingValues['LeftEngraving'] = First_Band_Engraving;
      if (Second_Band_Engraving) pendingValues['RightEngraving'] = Second_Band_Engraving;

      setIframeLoadActionCount(count => count + 3);
      const pendingRingSideEvents = Object.entries(pendingValues).map(([action, value]) => ({ action, value }));
      setPendingEvents(pendingRingSideEvents);
    }

    if (Object.keys(diamond.details).length > 0) {
      const {shape, source, carat} = diamond.details;
      const details = {shape: shape as string, type: source as string};
      selectedData(details, carat as string);
    } else if (diamondId) {
      DetailsData(diamondId);
    } else {
      optionsData({}, null);
    }
  }, []);

  useEffect(() => {
    if (!hasEffectRun.current) {
      TooltipDataFetch(setTooltipDataLoading, SetTooltipDataSuccess, SetTooltipDataError);
    }
    return () => {
      hasEffectRun.current = true;
    }
  }, [])

  useEffect(() => {
    const {Center_Diamond_Size, Diamond_Shape, Ring_Crown, Ring_Side, Ring_Style, Wedding_Band} = ring.options || {};
    const messages = ["LeftBand", "RightBand"];
    if(Wedding_Band && !isEditableRingOptions.current){
      if (Wedding_Band !== 'No Band' && !footer.isRedirectToEdit && styleId.includes('RB')) {
        setIframeLoadActionCount(count => count + 1);
        sendMessage('LoadBand', `${styleId}/${styleId?.includes('RB') ? `${Ring_Style}${Ring_Crown}`: Ring_Crown}/${Diamond_Shape}/${Center_Diamond_Size}/${Ring_Side}/bands`);
        bands3DModel();
      } else {
        messages.forEach(message => window.postMessage({action: message, value: false}));
      }
    }
  }, [ring.options["Wedding_Band"]]);

  const validate= useRef(false);

  useEffect(()=>{
    if(fieldValidation.length > 0){
      handleCheckValidation();
    }
  },[ring.options])

  const handleCheckValidation = useCallback(()=>{
      const fieldObject = Object.keys(ring.optionsData);
      setFieldValidation([]);
      fieldObject.forEach((field:string)=>{
        const checkData = !Object.keys(ring.options).includes(field);
        if(checkData){
          validate.current = true;
          setFieldValidation([...fieldValidation,field]);
        }
      })
  },[setFieldValidation,ring.optionsData,ring.options])

  const handleSelectSetting = useCallback(()=>{
    handleCheckValidation();
    if(validate.current){
      validate.current = false;
      return ;
    }
   /* window.postMessage({action: "DownloadSnapshot"});
    set3DImageLoading();*/
      setIsShowFooter(true);
      if (
          screenManager &&
          (screenManager as any).changeScreen
      ) {
        (screenManager as any).props.setStateManagerData(
            'selectedSetting',
            ring.options,
        );

        if (Object.keys(diamond.details).length > 0) {
          (screenManager as any).props.setStateManagerData(
              'cost',
              (diamond.details.diamond_price as number) + +ring.ringPrice,
          );

          // await storeDesignData();

          return setFooterToggle();
        }
        if (ring.options) {
          const {
            Diamond_Type,
            Diamond_Shape,
            Center_Diamond_Size,
          } = ring.options;
          const findNearestValue = styleId.includes('RP') ? startWithSettingValue :buildYourOwnValue;
          const selectedDiamondOptions = {
            source: Diamond_Type,
            shape: [Diamond_Shape],
            carat: `${
                Number(findNearestValue[Center_Diamond_Size]) -
                Number(findNearestValue[Center_Diamond_Size]) * 0.1
            }-${
                Number(findNearestValue[Center_Diamond_Size]) +
                Number(findNearestValue[Center_Diamond_Size]) * 0.1
            }`,
          };
          Object.entries(selectedDiamondOptions).forEach(([key,value]) => {
            setRingOptions(key, value);
          })
          setAfterRingOptions(selectedDiamondOptions);
        }
        setSettingTabIconTrue();
        // navigate('/select-diamond');
        (screenManager as any).changeScreen({
          viewName: 'diamond',
          id: null,
        });
      }
      /*if(Object.keys(diamond.details).length > 0){
              return setFooterToggle();
          }
          if(ring.options){
              const {Diamond_Type,Diamond_Shape,Center_Diamond_Size} = ring.options;
              const selectedDiamondOptions = {
                  source: Diamond_Type,
                  shape: [Diamond_Shape],
                  carat: `${Number(centerDiamondSizeValue[Center_Diamond_Size]) - Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}-${Number(centerDiamondSizeValue[Center_Diamond_Size]) + Number(centerDiamondSizeValue[Center_Diamond_Size])*0.1}`
              };
              setAfterRingOptions(selectedDiamondOptions)
          }
          setSettingTabIconTrue();
          navigate('/select-diamond');
      }}*/
  },[screenManager,setSettingTabIconTrue,setAfterRingOptions,setRingOptions,ring.options,setIsShowFooter])

  return {
    handlePDPOptions,
    handle3DObjectView,
    navigate,
    productName,
    handleAllOptions,
    styleId,
    handleSelectSetting,
    fieldValidation,
    isEditableRingOptions
  };
};
export default usePdpBuilder;
