import { useNavigate } from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import {
  recentDataSuccessType,
  setBackDiamondDetailsType,
  setDiamondDetailsFailType,
  setDiamondDetailsLoadingType,setIsShowFooterType,
  setDiamondDetailsSuccessType, setFooterToggle, setInitialDiamondOption, setInitialOptionType, TableData,
} from "../../types";
import {HKCustomize, HKRing} from "../Images";
import { useParams } from 'react-router-dom';
import {propertyMappings, source} from "../Diamond/useDiamond";

const useDiamondDetailsPage = ({ring,diamond,setDiamondDetailsSuccess,setIsShowFooter,instanceData: { screenManager },
  setDiamondDetailsLoading,setInitialOption,setBackDiamondDetails,
  setDiamondDetailsFail,setInitialDiamondOption,setFooterToggle,setDiamondTabIconTrue,setJourneyStartedFrom}:{ring: any,diamond: any,setDiamondDetailsLoading:setDiamondDetailsLoadingType ,setBackDiamondDetails:setBackDiamondDetailsType, setInitialOption:setInitialOptionType, setDiamondDetailsSuccess:setDiamondDetailsSuccessType,setDiamondDetailsFail:setDiamondDetailsFailType,setInitialDiamondOption:setInitialDiamondOption,setFooterToggle:setFooterToggle,recentDataSuccess: recentDataSuccessType,setIsShowFooter:setIsShowFooterType,instanceData: any,setDiamondTabIconTrue:(key:string)=>void,setJourneyStartedFrom:(value:string)=>void}) => {
  const {id}  = useParams();
  const navigate = useNavigate();
  const hasEffectRun = useRef(false);
  const DetailsData = useCallback(async () => {
    setDiamondDetailsLoading();
    try {
      const currentUrl = window.location.href;
      const match = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId =match?.[1] || '';
      // if (match) {
      //   diamondId = match[1];
      // } else {
      //   console.log('diamondId not found in the URL.');
      // }

      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/?id='${diamondId}'`,
      );
      if (!response.ok) {
        setDiamondDetailsFail(
          new Error(`HTTP error! Status: ${response.status}`),
        );
      }
      const result: TableData = await response.json();

      Object.keys(propertyMappings).forEach((property: string) => {
        result[property] = propertyMappings[property][result[property] as number  - 1];
      });
      const updatedResult = {
        ...result,
        source: result.source === 'HK-ND' ? source[0] : source[1],
        diamond_price : result.diamond_price !== null ? `${result.diamond_price}` : null
      }

      setDiamondDetailsSuccess(updatedResult);
    } catch (e) {
      setDiamondDetailsFail(e);
    }
  },[id,setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail,fetch]);
    useEffect(()=>{
      if(!hasEffectRun.current){
        setInitialDiamondOption();
        DetailsData();
      }
      if(ring.isBackToDiamondSelection){
        setInitialOption();
        setBackDiamondDetails(false);
      }
      (screenManager as any).props.setStateManagerData(
          'currentScreen',
          'Diamond details screen',
      );
      (screenManager as any).props.setStateManagerData(
          'currentView',
          'Diamond details view',
      );
      return () => {
        hasEffectRun.current = true;
      }
    },[])
    const handleContinueWithSetting = () => {
      setJourneyStartedFrom('details');
      setIsShowFooter(true);
      // navigate(`/start-with-setting?diamondId=${id}`);
      const currentUrl = window.location.href;
      const match = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId =match?.[1] || '';
      screenManager.changeScreen({ viewName: 'setting', id: diamondId });
    };
    const handleDesignYourOwnEngagement = () => {
      setJourneyStartedFrom('details');
      setIsShowFooter(true);
      // navigate(`/ring-builder?styleId=RB001&diamondId=${id}`);
      const currentUrl = window.location.href;
      const match_diamondId = currentUrl.match(/diamondId=([^&]*)/);
      let diamondId=match_diamondId?.[1] || '';
      const match_styleId = currentUrl.match(/styleId=([^&]*)/);
      let styleId=match_styleId?.[1] || '';
      
      screenManager.changeScreen({ viewName: 'dyo',styleId:styleId||'RB001', id: diamondId });
    };
  const dialogDetails = [
    {
      title: `Continue With a Setting`,
      img: HKRing,
      paragraph: `Select a ring setting from our exclusive curation.`,
      handleClick: handleContinueWithSetting,
    },
    {
      title: `Design Your Own`,
      img: HKCustomize,
      paragraph: `Design your own engagement ring by customizing your ring’s style, setting, metal and so much more.`,
      handleClick: handleDesignYourOwnEngagement,
    },
  ];

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const closeDialogBox = useCallback(() => {
    setIsOpenDialog(false);
  },[setIsOpenDialog])

  const openDialogBox = useCallback(() => {
    setIsOpenDialog(true);
  },[setIsOpenDialog])

  const handleSelectThisDiamond = () => {
    if(Object.keys(ring.options).length > 0){
      return setFooterToggle();
    }
    (screenManager as any).props.setStateManagerData(
      'selectedDiamond',
      diamond.details,
    );

    openDialogBox();
  }
  const handleBackDiamondSearch = useCallback(() => {
    // recentDataSuccess(diamond.details);
    setDiamondDetailsSuccess({});
    // navigate(-1);
    if(Object.keys(ring.options).length > 0){
      setIsShowFooter(true);
    }else {
      setIsShowFooter(false);
    }
    screenManager.changeScreen({ viewName: 'diamond', id: null });
    (screenManager as any).props.setStateManagerData(
        'selectedDiamond',
        '',
    );
  },[navigate,setDiamondDetailsSuccess])
  return {
    navigate,
    handleSelectThisDiamond,
    isOpenDialog,
    closeDialogBox,
    openDialogBox,
    dialogDetails,
    handleBackDiamondSearch
  };
};
export default useDiamondDetailsPage;
