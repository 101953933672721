import CartConstant from "../constant/CartConstant";

export const set3DImageLoading = () => ({
  type: CartConstant.THREE_D_IMAGE_LOADING
});

export const set3DImageSuccess = (value:string) => ({
  type: CartConstant.THREE_D_IMAGE_SUCCESS,
  payload: value
});

export const setInitialCart = () => ({
  type: CartConstant.SET_INITIAL_CART,
});

export const setCartImageSuccess = (value:string[]) => ({
  type: CartConstant.CART_IMAGE_SUCCESS,
  payload: value
});

export const setCartThumbImageSuccess = (value:string[]) => ({
  type: CartConstant.CART_THUMB_IMAGE_SUCCESS,
  payload: value
});
