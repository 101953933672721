import DiamondDetailsScreen from "./components/DiamondDetailsPage/DiamondDetailsScreen";
import CMSScreen from "./components/CMSPage/CMSScreen";
import DiamondScreen from "./components/Diamond/DiamondScreen";
import StartWithSettingScreen from "./components/StartWithSetting/StartWithSettingScreen";
import BuildOwnScreen from "./components/BuildOwn/BuildOwnScreen";

class ComponentManger {
  static findComponent(path: string) {
    switch (path) {
      case './components/DiamondDetailsPage/DiamondDetailsScreen':
        return DiamondDetailsScreen;
      case './components/Diamond/DiamondScreen':
        return DiamondScreen;
      case './components/StartWithSetting/StartWithSettingScreen':
        return StartWithSettingScreen;
      case './components/BuildOwn/BuildOwnScreen':
        return BuildOwnScreen;
      default:
        return CMSScreen;
    }
  }
}

export default ComponentManger;
