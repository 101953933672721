import React, { FC } from "react";
import classes from './Tab.module.css'
import Icon from "../../Icons";
import {TabProps} from "../../../types";

const Tab: FC<TabProps> = ({ label, onClick, isActive, isIcon, iconName,isNotShow }) => {
    return (
        <div
            onClick={onClick}
            className={`${classes.tab} ${isActive ? classes.active : ''} ${isNotShow ? classes.none : classes.show }`}
        >
            {isIcon &&
            <Icon name={iconName} />
            }
            {label}
        </div>
    )
}

export default Tab;
