import {ChangeEvent, useEffect, useState} from "react";
import {setRingOptionsType} from "../../types";
import debounce from "debounce";

const useFloatingLabel = ({maxLength,setRingOptions,keyName,value} : {value: string,maxLength:number,keyName: string,setRingOptions:setRingOptionsType}) => {
    const [inputValue, setInputValue] = useState('');
    useEffect(()=>{
        if(value){
            setInputValue(value);
        }
    },[])

    const debouncedHandleChange = debounce(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            if (newValue.length <= maxLength) {
                let action = "";
                switch (keyName) {
                    case "Engraving":
                        action = "ShankEngraving";
                        break;
                    case "First_Band_Engraving":
                        action = "LeftEngraving";
                        break;
                    case "Second_Band_Engraving":
                        action = "RightEngraving";
                        break;
                    default:
                        break;
                }
                if (action !== "") {
                    window.postMessage({ action, value: newValue });
                }
            }
        },
        500
    );
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setInputValue(newValue)
            setRingOptions(keyName, newValue);
        }
        debouncedHandleChange(event);
    };
    return {
        handleChange,
        inputValue
    }
}
export default useFloatingLabel;
