import React, { FC } from 'react';
import { MaterialReactTable } from 'tanstack-tailwind-table';
import ExpandRowDetails from './ExpandRowDetails';
import { PropsFromScreenManager, TableData } from '../../types';
import { RootState } from '../../reducers/RootReducer';
import {setExpandedRow, compareDataSuccess, removeCompareData, toggleScrollTop} from '../../action/TableAction';
import { connect, ConnectedProps } from 'react-redux';
import UseTable from './useTable';

const mapStateToProps = (state: RootState) => ({
  table: state.table,
  filter:state.filter
});

const mapDispatchToProps = {
  setExpandedRow,
  removeCompareData,
  compareDataSuccess,
  toggleScrollTop
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromTableRedux = ConnectedProps<typeof connector>;

export interface TableType extends PropsFromTableRedux {
  data: TableData[];
  isLoading: boolean;
  error: unknown | string;
}

const Table: FC<TableType & PropsFromScreenManager> = ({
  data,
  isLoading,
  error,
  setExpandedRow,
  table,
  instanceData,
  filter,
  compareDataSuccess,
  removeCompareData
}) => {
  const { isMobileView, tableData, columns,compareDataIds } = UseTable({ data, table,filter });

  if (error) {
    return <div className={'p-5 mx-auto'}>Error...</div>;
  }

  return (
    <div className={'mx-auto max-w-site'}>
      <MaterialReactTable
        //@ts-ignore
        columns={columns}
        data={tableData}
        enableTopToolbar={false}
        enableExpandAll={false}
        enableSorting={true}
        enableColumnActions={false}
        enablePagination={false}
        enableTableHead={!isMobileView}
        initialState={{
          compareDataSuccess,
          removeCompareData,
          setExpandedRow,
          toggleScrollTop,
          activeTab: table.activeTab,
          isMobileView: isMobileView,
          isLoading: isLoading,
          instanceData:instanceData,
          compareDataIds:compareDataIds,
          scrollToTop:table.scrollToTop,
        }}
        renderDetailPanel={({ row }) => {
          return !isMobileView ? (
            <ExpandRowDetails row={row.original} instanceData={instanceData} />
          ) : (
            ''
          );
        }}
      />
    </div>
  );
};

export default connector(Table);
