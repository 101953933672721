import React, { FC } from 'react'
import PdpBuilder from '../PdpBuilder'
import MainImageSlider from '../MainImageSlider'
import useBuildOwn from "./useBuildOwn";
import { PropsFromScreenManager } from '../../types';
import classes from './BuildOwn.module.css'
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import {RootState} from "../../reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";
import {setCartImageSuccess,setCartThumbImageSuccess} from "../../action/CartAction";

export const iFrameHost = localStorage.getItem('dev3D') ? "http://localhost:5173" : "https://hk-ring-builder.3dviewer-embeddable.pages.dev";
const BuildOwn:FC<PropsFromScreenManager & PropsFromCartRedux> = (props) => {
    const { instanceData } = props;
   const {
     img,
     thumbImage,
     isIcon,
     viewSrc,
     iframeLoadActionCount,
     setIframeLoadActionCount,
   } = useBuildOwn(props);

  return (
    <>
        <Header instanceData={instanceData}/>
        <main className={classes.root}>
            <div className={classes.container}>
                <div className={classes.slider}>
                    <div className={`${classes.viewRoot} sliderRef`}>
                        <MainImageSlider
                          viewSrc={viewSrc}
                          img={img}
                          thumbImage={thumbImage}
                          isIcon={isIcon}
                          is360={true}
                          iframeLoadActionCount={iframeLoadActionCount}
                        />
                    </div>
                </div>
                <div className={classes.detail}>
                    <PdpBuilder
                      instanceData={instanceData}
                      iframeLoadActionCount={iframeLoadActionCount}
                      setIframeLoadActionCount={setIframeLoadActionCount}
                    />
                </div>
            </div>
        </main>
        <StickyFooter instanceData={instanceData}/>
    </>
  )
}
const mapStateToProps = (state: RootState) => ({
  ring: state.ring,
  footer: state.footer
});

const mapDispatchToProps = {
  setCartImageSuccess,
  setCartThumbImageSuccess
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromCartRedux = ConnectedProps<typeof connector>;
export default connector(BuildOwn)
