import StartWithSettingFilterConstant from "../constant/StartWithSettingFilterConstant";

export const setStartWithSettingFilterLoading = () => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_LOADING
});
export const setStartWithSettingFilterSuccess = (val: string | string[]) => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_DATA_SUCCESS,
    payload: val,
});
export const setStartWithSettingFilterFail = (error:unknown) => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_FAIL,
    payload : error
});
export const setStartWithSettingFilter = (key: string, value: string | string[]) => ({
    type: StartWithSettingFilterConstant.SET_START_WITH_SETTING_FILTER,
    payload: { key, value },
});
export const setResetStartWithSettingFilter = () => ({
    type: StartWithSettingFilterConstant.SET_RESET_START_WITH_SETTING_FILTER,
});

export const setStartWithSettingProductLoading = () => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_LOADING
});
export const setStartWithSettingProductSuccess = (val:any) => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_SUCCESS,
    payload: val,
});
export const setStartWithSettingProductFail = (error:unknown) => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_FAIL,
    payload : error
});
export const setFilterMinMaxProperty = (value :{[key: string] : {[key:string] : number}}) => ({
    type: StartWithSettingFilterConstant.START_WITH_SETTING_MIN_MAX_VALUE,
    payload: value
})
export const removeFilterKey = (key: string) => ({
    type: StartWithSettingFilterConstant.REMOVE_FILTER_KEY,
    payload: {key},
});
export const setChangeMinMaxValue = (value : boolean) => ({
    type: StartWithSettingFilterConstant.SET_START_WITH_SETTING_MIN_MAX_VALUE_CHANGE,
    payload: value
});

export const setInitialStartWithSettingData = ()=>({
    type: StartWithSettingFilterConstant.SET_INITIAL_START_WITH_SETTING
})

export const setAfterDiamondSelectOptions = (value : {[key:string] : string | string[]}) =>({
    type: StartWithSettingFilterConstant.SET_AFTER_DIAMOND_SELECTED_OPTIONS,
    payload: value
})

export const setSwatchColor = (value:string) => ({
    type: StartWithSettingFilterConstant.SET_SWATCH_COLOR,
    payload: value
})
