import {ColorImgType, SliderContent, TableData} from "../types";
import {
  Asscher,
  Cushion,
  Emerald,
  Marquise,
  Oval,
  Pear,
  Princess,
  Radiant,
  RoseGold,
  Round,
  SilverGold,
  YellowGold
} from "../components/Images";
import {useEffect} from "react";

export const colorName: { [key: string]: string } = {
  WG: 'White',
  YG: 'Yellow',
  RG: 'Rose',
};

export const ColorImg: ColorImgType = {
  "White": SilverGold,
  "Yellow": YellowGold,
  "Rose": RoseGold,
};

export const DiamondShapeContent: SliderContent[] = [
  {
    imgPath: Round,
    label: "Round",
    code: 'RND'
  },
  {
    imgPath: Princess,
    label: "Princess",
    code: 'PRC'
  },
  {
    imgPath: Emerald,
    label: "Emerald",
    code: 'EMR'
  },
  {
    imgPath: Oval,
    label: "Oval",
    code: 'OVA'
  },
  {
    imgPath: Pear,
    label: "Pear",
    code: 'PEA'
  },
  {
    imgPath: Radiant,
    label: "Radiant",
    code: 'RAD'
  },
  {
    imgPath: Marquise,
    label: "Marquise",
    code: 'MAR'
  },
  /*{
    imgPath: Cushion,
    label: "Cushion",
    code: 'CUS'
  },
  {
    imgPath: Asscher,
    label: "Asscher",
    code: 'ASC'
  },*/
  /*{
      imgPath: Heart,
      label: "Heart",
      code: 'HAT'
  }*/
];

export const rpid = sessionStorage.getItem('rpid');
export const builder_mode = sessionStorage.getItem('builder_mode');
export const uuid = sessionStorage.getItem('design_uuid');


export const SetEditableData = async (key:string,setDiamondDetailsSuccess:(data:TableData)=>void,setExpandedRow:(key:number,value:string)=>void,setRingOptions:(key: string, value: string | string[])=>void,setSelectedRingDetails:(key: string, value: string )=>void,setFilter:(key: string, value: string | string[] | boolean) =>void)=>{
  /*try {
    const response = await fetch(`${process.env.REACT_APP_GET_EDITABLE_DATA}?hkuuid=${JSON.parse(key)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();

    // console.log(data,'result');
    const SettingData = JSON.parse(result.ring_options_code);
    if(result){
      setDiamondDetailsSuccess(JSON.parse(result?.diamond_details));
      setExpandedRow(0,JSON.parse(result?.diamond_details).id as string);
      Object.entries(SettingData).map(([key,value])=> setRingOptions(key,value as string));
      Object.entries(JSON.parse(result?.setting_details)).map(([key,value])=> setSelectedRingDetails(key,value as string));
      Object.entries(JSON.parse(result?.diamond_filter_options)).map(([key,value])=> setFilter(key,value as string));
    }
  } catch (error) {
    // console.error(error);
  }*/
}


export const DiamondLabels :{[key:string]:string} ={
  Round:"Round",
  Princess:"Princess-Cut",
  Pear:"Pear-Shaped",
  Oval:"Oval",
  Emerald:"Emerald-Cut",
  Radiant:"Radiant-Cut",
  Marquise:"Marquise-Cut",
  Cushion:"Cushion-Cut",
  Heart:"Heart-Shaped"
}