import React, { FC } from 'react';
import Button from '../Button';
import Icon from '../Icons/Icon';
import MainImageSlider from '../MainImageSlider';
import { RootState } from '../../reducers/RootReducer';
import { setFooterToggle, setRedirectEditPage } from '../../action/FooterAction';
import { setCartImageSuccess, setCartThumbImageSuccess } from '../../action/CartAction';
import { setSettingTabIconTrue, setDiamondTabIconTrue } from '../../action/HeaderAction';
import { connect, ConnectedProps } from 'react-redux';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';
import { PropsFromScreenManager } from '../../types';
import useCart from './useCart';
import IcoMoonConstant from '../../constant/IcoMoonConstant';
import classes from './Cart.module.css';

const Cart: FC<PropsFromCartRedux & PropsFromScreenManager> = (props) => {
  const {setFooterToggle,diamond,cart,ring,instanceData: { screenManager }} = props;
  const { allDetails, handleReviewClick, Ring_Style, diamond_price } = useCart(props);
  const handleStartAgain = ()=>{
    //@ts-ignore
    window.location.href = window.location.origin;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={`${classes.slider} sliderRef`}>
            <MainImageSlider is360={false} img={cart.image} isIcon={false} thumbImage={cart.cartThumbImage} isLoading={cart.isLoading} />
          </div>
          <div className={classes.description}>
            <div className={classes.header}>
              <h1 className={classes.heading}>{Ring_Style} Engagement Ring</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.product}>
                {allDetails
                  .filter((details) => Object.keys(details.showDetails).length > 0)
                  .map((details, index) => {
                    return (
                      <div key={index} className={classes.productItem}>
                        <div className={classes.pricewrap}>
                          <h3 className={classes.subtitle}>{details.title}</h3>
                          <h3 className={classes.price}>{details.price}</h3>
                        </div>
                        <div className={classes.accordions}>
                          <div className={classes.buttonWrap}>
                            {details.editAction && (
                              <Button type="button" className={classes.action} onClick={details.editAction}>
                                <Icon name={IcoMoonConstant.HK_SQUARE_PENCIL} />
                                <span className={''}>Edit</span>
                              </Button>
                            )}
                            <Button type="button" className={classes.action} onClick={details.moreAction}>
                              <span className={''}>{details.isShowMore ? 'Less' : 'More'}</span>
                              <Icon name={IcoMoonConstant.HK_CHEVRON_DOWN_M} className={details.isShowMore ? classes.iconswap : ''} />
                            </Button>
                          </div>
                          <ul className={`${classes.acc_wrap} ${details.isShowMore && classes.isActive}`}>
                            {Object.entries(details.showDetails).map(([key, value], index) => {
                              return (
                                <li className={classes.item} key={index}>
                                  <span className={classes.span}>{key}</span>
                                  <span className={`${classes.label} ${key.toLowerCase() !== 'engraving' ? classes.capitalizeText : ''} `}>
                                    {value ? value : '-'}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={classes.total_wrap}>
                <div className={classes.total_label}>Total Price</div>
                <div className={classes.total_price}>{`$${ToLocalStringConvert(diamond_price ? +diamond_price + +ring.ringPrice : +ring.ringPrice)}`}</div>
              </div>
            </div>
            <div className={classes.footer}>
              <Button
                type="button"
                buttonType="btnPrimary"
                className={classes.primaryAction}
                isDisabled={!(Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0)}
                onClick={handleReviewClick}
              >
                <span>Review</span>
              </Button>
              {Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0 && (
                <div className={classes.secondaryAction}>
                  <Button type="button" className={classes.button} onClick={() => setFooterToggle()}>
                    {/*<Icon name={IcoMoonConstant.HK_CHEVRON_LEFT_M} />*/}
                    <span>Back</span>
                  </Button>
                  {/*<Button
                    type="button"
                    className={classes.button}
                    onClick={handleStartAgain}
                  >
                    <span>Start Again</span>
                  </Button>*/}
                </div>
              )}
              <ul className={classes.list}>
                <li>
                  Diamond price may change when selected or added to wishlist.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  diamond: state.diamond,
  ring: state.ring,
  header: state.header,
  filter: state.filter,
  footer: state.footer,
  cart: state.cart,
  settingFilter:state.settingFilter,
  table:state.table,
  stateManger:state.stateManager
});

const mapDispatchToProps = {
  setFooterToggle,
  setSettingTabIconTrue,
  setDiamondTabIconTrue,
  setRedirectEditPage,
  setCartImageSuccess,
  setCartThumbImageSuccess
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromCartRedux = ConnectedProps<typeof connector>;

export default connector(Cart);
